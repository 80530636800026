/* ------------------- */
/* Custom properties   */
/* ------------------- */
@import url('https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&family=Italianno&display=swap');
:root {
  /* colors */
  --clr-dark: 230 35% 7%;
  --clr-light: 231 77% 90%;
  --clr-white: 0 0% 100%;
  
  /* font-sizes */
  --fs-900: 9.375rem;
  --fs-800: 6.25rem;
  --fs-700: 3.5rem;
  --fs-600: 2rem;
  --fs-500: 1.75rem;
  --fs-400: 1.125rem;
  --fs-300: 1rem;
  --fs-200: 0.875rem;
  
  /* font-families */
  --ff-serif: "Bellefair", serif;
  --ff-sans-cond: "Barlow Condensed", sans-serif;
  --ff-sans-normal: "Barlow", sans-serif;
}


/* ------------------- */
/* Reset               */
/* ------------------- */

/* https://piccalil.li/blog/a-modern-css-reset/ */

/* Box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reset margins */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
picture {
  margin: 0; 
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
}


.container {
  margin-inline: auto;
  width: 100%
}


/* set up the body */
body {
  font-family: var(--ff-sans-normal);
  font-size: var(--fs-400);
  color: hsl( var(--clr-white) );
  line-height: 1.5;
  min-height: 100vh;
  width: 100%;
  background-color: white;


}

.main_container {
  display: grid;
 grid-template-columns: 900px 1fr;
 grid-template-areas: "left-side right-side";
}


.left_side{
  grid-area: left-side;
  background-color: #08090B;
 height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  place-items: center;
  

}
.left_items{
  display: flex;
  align-items: center;
  margin-inline: auto;
}

.left_items img{
  margin-right:10px;
}
.right_side{
  
 
background-color: white;
  display: flex;
  align-items: center;
}

.right_items{
   grid-area: right-side;
  color: black;
  height: 400px;
  width: 300px;
  margin-inline: auto;

}


h5{
  font-family: Italianno;
  font-size: 30px;
  font-weight: 400px;
}

.ptag{
  font-family: var(--ff-sans-normal);
  font-size: var(--fs-200);
 letter-spacing: 2px;
 color: rgb(72, 72, 72);
}

a{
  text-decoration: none;
}

.login_items{
  margin: 60px auto;
  padding:10px 10px;
}

.login_inputs{
  margin:30px auto;

}



.login_inputs input,button{
  display: block;
  margin-top: 20px;
  width: 85%;
  height: 37px;
  border-radius: 6px;
  outline: none;
  border: none;
}


.login_inputs input{
  background-color: #dfdede;
  padding:0px 20px;
  font-family: var(--ff-sans-normal);
  font-size: var(--fs-200);
  
}

.login_button{
  background-color: black;
  color: white;
  cursor: pointer;
  font-family: var(--ff-serif);
  font-size: var(--fs-400);
  text-decoration: none;
  border-radius: 6px;
  margin-top: 20px;
}