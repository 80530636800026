.header{
  margin:3px ;
  display: flex;
  
}
.header_logo{
  width: 40px;
  margin-left: 50px;
}

h1,p{
  color: black;
}


.header_banner{
  background-color: rgb(162, 102, 6);
  display: flex;
  height: 50px;
  align-items: center;
  
}

.header_banner a{
  text-decoration: none;
}

.header_banner_right{
  background-color: rgb(191, 190, 189);
  width: 100%;
}


.top_layer label{
    display: block;
    
}


.sec_container {
   
    width: 90%;
    height: 2200px;
    margin-top: 40px;
    margin-inline: auto;
    align-items: center;
    padding: 50px;
    background-color: rgb(212, 211, 210);
    border-radius: 41px;

}




#profileDisplay {
    position: absolute;
    top: 2%;
    left: 80%;
    width: 235px;
    height: 242px;
    background: #DADADA;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 44px;
}

.top_layer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: auto;
    margin-bottom: 50px;
 
}

.top_layer .profile img{
    width: 200px;
    background: #DADADA;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 44px;
}





.top_layer input {
    margin: 10px 0px 0px 0px;
    width: 230px;
    height: 45px;
    border-radius: 21px;
    font-family: 'Roboto';
    font-style: bold;
    font-weight: 900px;
    font-size: 40px;
    text-align: center;
}





.personal_info h2 {
    text-align: center;
    margin: 0rem 0rem 3rem 0rem;
    font-family: 'Roboto';
    font-style: bold;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
}

.personal_info .addi {
    margin: 40px 0px 10px 0px;
}

.personal_info .text_pers {
    display: flex;
    justify-content: space-between;
}

.personal_info .text_pers input {
    padding: 0px 20px 0px 20px;
    width: 350px;
    height: 45px;
    border-radius: 9px;
    outline: none;
    border: 1px solid #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100px;
    font-size: 20px;
    margin-right: 20px;
}

.personal_info .field_form {
    display: flex;
    justify-content: space-between;
    margin: 1px 0px 30px 0px;
}

.personal_info .field_form input {
    padding: 0px 10px 0px 10px;
    height: 45px;
    border-radius: 9px;
    outline: none;
    border: 1px solid #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100px;
    font-size: 20px;
    margin: 0px 10px;
    margin-right: 20px;
}

.employment_info h2 {
    text-align: center;
    margin: 5rem 0rem 3rem 0rem;
    font-family: 'Roboto';
    font-style: bold;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
}

.employment_info .text_pers {
    display: flex;
    justify-content: space-between;
}

.employment_info .field_form {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 30px 0px;
}

.employment_info .field_form input {
    padding: 0px 10px 0px 10px;
    height: 45px;
    border-radius: 9px;
    outline: none;
    border: 1px solid #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100px;
    font-size: 20px;
    margin-right: 20px;
}

.family_info h2 {
    text-align: center;
    margin: 5rem 0rem 3rem 0rem;
    font-family: 'Roboto';
    font-style: bold;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
}

.family_info .text_pers {
    display: flex;
    justify-content: space-between;
}

.family_info .field_form {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 30px 0px;
}

.family_info .field_form input {
    padding: 0px 10px 0px 10px;
    height: 45px;
    border-radius: 9px;
    outline: none;
    border: 1px solid #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100px;
    font-size: 20px;
    margin-right: 20px;
}

.department_info h2 {
    text-align: center;
    margin: 5rem 0rem 3rem 0rem;
    font-family: 'Roboto';
    font-style: bold;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
}

.department_info .text_pers {
    display: flex;
    justify-content: space-between;
}

.department_info .field_form {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 30px 0px;
}

.department_info .field_form input {
    padding: 0px 10px 0px 10px;
    height: 45px;
    border-radius: 9px;
    outline: none;
    border: 1px solid #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100px;
    font-size: 20px;
    margin-right: 20px;
}

.form_buttons {
    display: flex;
    width: 400px;
    text-align: center;
    margin-inline:auto;
    padding-bottom: 50px;
    
}

#sub_button {
    width: 150px;
    padding: 10px;
    text-align: center;
    background-color: rgba(36, 22, 50, 0.884);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
    border-radius: 21px;
    font-family: 'Roboto';
    font-style: bold;
    font-weight: 800;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

#cancel_button {
    width: 150px;
    padding: 10px;
    text-align: center;
    background-color: red;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
    border-radius: 21px;
    font-family: 'Roboto';
    font-style: bold;
    font-weight: 800;
    font-size: 20px;
    color: white;
    cursor: pointer;
}