

.main{
  display: grid;
grid-template-columns: 1fr 1fr;
}

.style-one{
  background-color: green;

}

.style-two{
  background-color: blue;
  
}