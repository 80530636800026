/* ------------------- */
/* Custom properties   */
/* ------------------- */
@import url('https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&family=Italianno&display=swap');
:root {
  /* colors */
  --clr-dark: 230 35% 7%;
  --clr-light: 231 77% 90%;
  --clr-white: 0 0% 100%;
  
  /* font-sizes */
  --fs-900: 9.375rem;
  --fs-800: 6.25rem;
  --fs-700: 3.5rem;
  --fs-600: 2rem;
  --fs-500: 1.75rem;
  --fs-400: 1.125rem;
  --fs-300: 1rem;
  --fs-200: 0.875rem;
  
  /* font-families */
  --ff-serif: "Bellefair", serif;
  --ff-sans-cond: "Barlow Condensed", sans-serif;
  --ff-sans-normal: "Barlow", sans-serif;
}


/* ------------------- */
/* Reset               */
/* ------------------- */

/* https://piccalil.li/blog/a-modern-css-reset/ */

/* Box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reset margins */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
picture {
  margin: 0; 
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
}





/* set up the body */
body {
  font-family: var(--ff-sans-normal);
  font-size: var(--fs-400);
  color: hsl( var(--clr-white) );
  line-height: 1.5;

}



.container{
  display: grid;
  grid-template-columns:250px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
  "sidebar header header header"
  "sidebar main main main";
  height:100vh;
}

.menu_icons{
  display: none;
}



/*----------Header--------*/
.header{
  grid-area: header;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;
  box-shadow: 0px 5px 7px -1px rgba(0,0,0,0.40);
  background-color: rgb(83, 81, 81);
}
.header_left{
  display: flex;
align-items: center;
color: white;

}



  
  .header_left a:visited {
    color: rgb(178, 67, 67);
    background-color: transparent;
    text-decoration: none;
  }

.header_content{
  display: flex;
  margin: 0px 60px;
  padding: 10px 0px;
  justify-items: center;

}

/*----------Sidebar--------*/
.sidebar{
  grid-area: sidebar;
  max-height: 100vh;
  background-color: #111112f4;
  overflow-y: hiden;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  overflow-x: hidden; 

}

.side_top{
  display: flex;
  width: 250px;
 align-items: center;
 max-height: 80px;
 box-shadow: 0px 5px 7px 2px rgba(0,0,0,0.60);

}
.logo_img img{
  width: 75px;
  margin:10px 0px
}
.logo_name{
  color: White;
  font-style: bold;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size:20px;
  margin: 0px 20px;
}

.other_name{
  color: #3b911c;
  font-style: bold;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size:20px;
}

#close_icon{
  display: none;
}


.side_list{
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;

}

.side_list_item{
  margin: 50px 10px;
  display: flex;
  padding: 10px 0px;
  color: white;
}
.side_list_item:hover{
  cursor: pointer;
  background-color: rgba(245, 245, 245, 0.367);
}


.sidebar_responsive{
  display: inline !important;
  position: absolute;
  z-index: 12 !important;
}


/*----------Main-content--------*/
.main_content{
  grid-area: main;
  overflow-y: auto;
  padding:30px 90px;
  color: black;
}

.main_title{
display: flex;
justify-content: space-between;
}

.main_cards{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:20px;
  justify-content: space-between;
  margin: 20px 0;
  height: 250px;
  width: 900px;
}

.card{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  border-radius: 3px;
  border-color: green;
  color: white;
  font-weight: bold;
  font-size: 25px;
  cursor: pointer;
  
}

.card:first-child{
  background-color: black;
}

.card:nth-child(2){
  background-color:black;
}

.card:nth-child(3){
  background-color: black;
}
.card:nth-child(4){
  background-color:black;
}

.card-inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side_list span{
  padding-right: 10px;
}
p{
  font-family: var(--ff-sans-normal);
  font-size: var(--fs-300);
  color: white;
}

.number_text{
  font-size: 50px;
  color: white;
}