.header{
  margin:3px ;
  display: flex;
  
}
.header_logo{
  width: 40px;
  margin-left: 50px;
}

h1,p{
  color: black;
}


.header_banner{
  background-color: rgb(162, 102, 6);
  display: flex;
  height: 50px;
  align-items: center;
  
}

.header_banner a{
  text-decoration: none;
}

.header_banner_right{
  background-color: rgb(191, 190, 189);
  width: 100%;
}


.sec_container {
   
  width: 90%;
  height: 100%;
  margin-top: 40px;
  margin-inline: auto;
  align-items: center;
  padding: 50px;
  background-color: rgb(212, 211, 210);
  border-radius: 41px;

}


.searchbar{
  width: 100%;
  margin:10px 10px;
  padding: 7px 15px;
  border: 1px solid black;
  border-radius: 10px;
}

.table{
  width:100%;
  margin:10px 10px;

}

.tablerow{
  width: 15%;
  border: none;
  border-bottom: 1px solid blanchedalmond;
}
