@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Roboto&display=swap');


* {
  margin: 0;
  padding: 0;
}



body {
  height: 100vh;
  background-color: #F4F4F4;
  ;
}

.container {
  margin: 10px 30px;
  padding: 5px;
  height: 100vh;
}


.header {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin: 50px 20px;
}

.header .title {
  color: rgba(13, 51, 93, 0.70);
  font-family: Roboto;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.header .title h3 {
  font-size: 30px;
}

.header .title p {
  font-size: 13px;
}


.header .logo img {
  width: 50px;
}

.formgroup {
  max-width: 100%;
  margin: 20px 20px;
  height: 180px;
}

.inputgroup {
  display: flex;
  width: 100%;
  justify-items: center;

}



.photo {
  display: flex;
  justify-content: space-between;

}

.photo .idmem {
  display: inline-block;
  max-width: 500px;
  margin: 0px 0px;


}



.photo .idmem input {
  width: 80px;
  height: 40px;
  border-radius: 15px;
  padding: 0px 10px;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 200;
  outline: none;
  border: none;
  background: #D8D8D8;

}

.photo .picture img {
  width: 180px;
  border: 1px solid black;
  border-radius: 18px;

}



.allitems {
  background: #EBEBEB;
  height: 100%;
  margin: 60px 20px;
  padding: 10px 20px;
  border-radius: 20px;


}



.groupinfo{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.personinfo .headinginfo {
  text-align: center;
}

.textpers {
display: flex;
flex-direction: column;
  justify-content: space-around;
  

}

.groupinfo .textpers label{
text-align: start;
color: #1A508B;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 100;
line-height: normal;
}

.textpers input {

  width: 230px;
  height: 46px;
  border-radius: 25px;
  border: 1px solid #000;
  background: #EBEBEB;
  padding: 0px 20px;
  outline: none;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  margin: 12px 0px;


}






.textpers .dropdown {
  width: 230px;
  height: 46px;
  border-radius: 25px;
  border: 1px solid #000;
  background: #EBEBEB;
  padding: 0px 20px;
  outline: none;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  margin: 12px 0px;


}


.bottomdata{
  margin-top: 30px;
}

h4 {
  color: #000;
  font-family: Roboto;
  font-size: 40px;
}

.formbuttons {
  display: flex;
  margin-inline: auto;
  justify-content: space-between;
  margin-bottom: 30px;
  max-width: 50%;
  margin-top: 20px;

}




.infotable {
  margin: 10px 0px;
  border: none;

}


.topper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.topper button {
  width: 150px;
  height: 50px;
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  border-radius: 66px;
  background: #1A508B;
  box-shadow: 3px 5px 9px 1px rgba(0, 0, 0, 0.40);
  border: none;



}



.outerwrapper {
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.9);
  min-width: fit-content;
  max-height: fit-content;
  border: 2px solid #FFF;
  background: #E0E0E0;
   margin: 60px 20px;
 
}

.tablewrapper {
  overflow-y: scroll;
  overflow-x: scroll;
  height: fit-content;
  max-height: 34.4vh;
  margin-top: 22px;
  margin: 15px;
  padding-bottom: 20px;

}


.titheinfo{
  margin: 120px 0px;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

table th {

  background-color: #133b5c;
  color: rgb(241, 245, 179);
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  outline: 0.7px solid black;
  border: 1.5px solid black;
}




.tablebordered {
  margin: 70px 0px;
}



table th,
table td {
  padding: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}

table td {
  text-align: left;
  font-size: 15px;
  border: 1px solid rgb(177, 177, 177);
  padding-left: 20px;
}

.submit_button {
  width: 150px;
  text-align: center;
  background-color: rgba(51, 127, 51, 0.884);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 21px;
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 800;
  font-size: 20px;
  color: white;
  cursor: pointer;
  border: none;
}

.confirm_button {
  width: 150px;
  text-align: center;
  background-color: rgba(241, 202, 43, 0.884);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 21px;
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 800;
  font-size: 20px;
  color: white;
  cursor: pointer;
  border: none;
}





.cancel_button {
  width: 150px;
  text-align: center;
  background-color: rgb(60, 30, 30);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 21px;
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 800;
  font-size: 20px;
  color: white;
  cursor: pointer;
  border: none;
}
